import React from "react";
import Header1 from "../components/Header/Header1";

import Contact1 from "../components/Contact/Contact1";
import Footer1 from "../components/Footer/Footer1";
import SimpleSlider1 from "../components/Sliders/Slick-slider1";

import Restorantform from "../components/Forms/Restorantform";
import Clients from "../components/Clients";
function Home() {
  return (
    <>
      <div id="home" className="full-screen-block">
        <Header1 />
        <SimpleSlider1 />
      </div>
      <Restorantform />
      <Clients />
      <Contact1 />
      <Footer1 />
    </>
  );
}

export default Home;
