import {
  Body,
  Container,
  Head,
  Heading,
  Html,
  Preview,
  Section,
  Text,
} from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
import React from "react";

const Kuryemail = ({ formData, basvuruData }) => {
  const tarih = new Date(formData?.dtarih);

  const gun = tarih.getDate();
  const ay = tarih.getMonth() + 1;
  const yil = tarih.getFullYear();

  const birlesikTarih = `${gun}/${ay}/${yil}`;

  const renderBasvuru = (senaryo) => {
    switch (senaryo) {
      case "basvuru1":
        return <strong>{basvuruData?.basvuruOne?.name}</strong>;

      case "basvuru2":
        return <strong>{basvuruData?.basvuruTwo?.name}</strong>;

      case "basvuru3":
        return <strong>{basvuruData?.basvuruThree?.name}</strong>;
      case "basvuru4":
        return <strong>{basvuruData?.basvuruFour?.name}</strong>;
      case "basvuru5":
        return <strong>{basvuruData?.basvuruFive?.name}</strong>;
      case "basvuru6":
        return <strong>{basvuruData?.basvuruSix?.name}</strong>;

      default:
        break;
    }
  };
  return (
    <Html>
      <Head />
      <Preview>Delivery Wi Form</Preview>
      <Tailwind
        config={{
          theme: {
            extend: {
              colors: {
                brand: "#2250f4",
                offwhite: "#fafbfb",
              },
              spacing: {
                0: "0px",
                20: "20px",
                45: "45px",
              },
            },
          },
        }}
      >
        <Body className="bg-offwhite text-base font-sans">
          <Container className="bg-white p-45">
            <Heading className="text-center my-0 leading-8">
              Delivery Wi Kurye Başvuru
            </Heading>

            <ul>
              <li className="mb-0">
                Ad : <strong>{formData?.ad}</strong>
              </li>
              <li className="mb-0">
                Soyad : <strong> {formData?.soyad}</strong>
              </li>
              <li className="mb-0">
                Telefon : <strong> {formData?.tel}</strong>
              </li>
              <li className="mb-0">
                DTarih : <strong> {birlesikTarih}</strong>
              </li>
              <li className="mb-0">
                Email : <strong>{formData?.email}</strong>
              </li>
              <li className="mb-0">
                Adress : <strong>{formData?.adress}</strong>
              </li>
            </ul>

            <Section className="mt-45">
              <ul>
                <li className="mb-0">
                  Ehliyet :{" "}
                  <strong>{formData?.A1check === true ? "A1 ," : ""}</strong>
                  <strong>{formData?.A2check === true ? "A2 ," : ""}</strong>
                  <strong>{formData?.Acheck === true ? "A ," : ""}</strong>
                  <strong>{formData?.Bcheck === true ? "B" : ""}</strong>
                </li>
                <li className="mb-0">
                  Araç :{" "}
                  <strong>{formData?.Motor === true ? "Motor , " : ""}</strong>
                  <strong>{formData?.Araba === true ? "Araba" : ""}</strong>
                </li>
                <li className="mb-0">
                  Deneyim Yılı : <strong> {formData?.deneyimtec} Yıl</strong>
                </li>
                <li className="mb-0">
                  Yeni Başlıyorum :{" "}
                  <strong>
                    {formData?.Yenicheck === true ? "Evet" : "Hayır"}
                  </strong>
                </li>
                <li className="mb-0">
                  Daha Önce Çalıştım : <strong>{formData?.deneyimyer} </strong>
                </li>
                <li className="mb-0">
                  Başvurular :{renderBasvuru(formData?.senaryo)}
                </li>
              </ul>
            </Section>
          </Container>

          <Container className="mt-20">
            <Text className="text-center text-gray-400 mb-45">
              Duzyayla Sok., No:8, Musalla Bağları Mah., Selçuklu, Konya
            </Text>
          </Container>
        </Body>
      </Tailwind>
    </Html>
  );
};

export default Kuryemail;
