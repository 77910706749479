import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../lib/config";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import "./style.css";
import { FaWhatsapp } from "react-icons/fa";
function Lightbox() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(API_URL + "/resim")
        .then((res) => {
          setData(res?.data);
        })
        .catch((error) => alert("Hata Oluştu"));
    };

    fetchData();
  }, []);

  return (
    <>
      <PhotoProvider>
        <div className="row">
          {data?.map((item) => {
            return (
              <div
                key={item?.index}
                className="col-lg-4 col-md-12 col-sm-12 col-xs-12 tw-px-0 md:tw-px-4"
              >
                <div className="single-portfolio-block tw-flex tw-items-center tw-flex-col">
                  <PhotoView src={API_URL + `${item.url}`}>
                    <img
                      src={API_URL + `${item.url}`}
                      className="img-fluid image-size"
                      alt={item?.name}
                    />
                  </PhotoView>
                  <div className="single-portfolio-title row tw-mb-0 md:tw-mb-8">
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 tw-mb-2 md:tw-mb-0">
                      <a
                        href={`https://wa.me/905311054208?text=${item.message}`}
                        className=" tw-bg-green-600 tw-px-4 tw-py-2 tw-flex tw-items-center tw-justify-center tw-gap-3 tw-rounded-3xl tw-text-nowrap tw-cursor-pointer hover:tw-text-slate-300"
                      >
                        <FaWhatsapp size={26} /> Sipariş Ver
                      </a>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 tw-flex tw-items-center tw-justify-center tw-mb-8 md:tw-mb-0">
                      <div className="tw-px-4 tw-py-2 tw-rounded-3xl tw-border tw-border-white tw-max-w-20">
                        {item?.price}₺
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </PhotoProvider>
    </>
  );
}

export default Lightbox;
