import React from "react";
import SideBar from "./SideBar";

const Layout = (props) => {
  return (
    <>
      <section className="tw-pt-12">
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <div className="col-sm-12 col-md-12 col-lg-10 col-xl-10">
              {props.children}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Layout;
