import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../lib/config";
import axios from "axios";
import { useForm } from "react-hook-form";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import "./table.css";
const AdminRef = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user !== null) {
      const myuser = JSON.parse(user);
      if (myuser.name !== "admin") navigate("/");
    } else {
      navigate("/");
    }
  }, [navigate]);
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(API_URL + "/ref")
        .then((res) => {
          setData(res.data);
        })
        .catch((error) => {
          alert("Hata Oluştu");
        });
    };

    fetchData();
  }, []);

  const [imageOne, setImageOne] = useState();
  const { register, handleSubmit } = useForm();
  const handleImageOne = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageOne(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("index", data.index);
    formData.append("file", data.Image);

    try {
      const response = await axios.post(API_URL + "/addref", formData);
      if (response.data) {
        alert("Başarıyla Eklendi");
        window.location.reload();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) alert("Hata Oluştu");
    }
  };
  const onDelete = async (id, name) => {
    // eslint-disable-next-line no-restricted-globals
    var result = confirm(
      name + " adlı resim silinecek. Devam etmek istiyor musunuz?"
    );
    if (result) {
      await axios
        .delete(`${API_URL}/ref/${id}`)
        .then(() => {
          alert("Başarıyla Silindi");
          window.location.reload();
        })
        .catch((error) => {
          alert("Hata Oluştu");
        });
    } else {
      console.log("Silme işlemi iptal edildi.");
    }
  };
  return (
    <Layout>
      <div>
        <div>
          <form
            id="MyContactForm"
            name="KuryeForm"
            className="tw-border tw-border-white tw-p-3"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-2">
              <img
                src={imageOne}
                className="tw-w-[120px] tw-h-[120px] tw-object-contain"
                alt="img1"
              />
              <input
                type="file"
                accept="image/*"
                className="tw-max-w-60"
                onChange={(e) => {
                  const file = e.target.files[0];
                  const maxSize = 2 * 1024 * 1024;

                  if (file.size > maxSize) {
                    alert("Dosya boyutu 2MB'dan fazla olamaz.");
                    e.target.value = "";
                  } else {
                    handleImageOne(e);
                    register(`Image`, {
                      shouldUnregister: true,
                      value: file,
                    });
                  }
                }}
              />
              <input
                type="number"
                id="name"
                className="tw-max-w-24"
                min={0}
                placeholder="No"
                {...register(`index`)}
              />
              <div className="text-center">
                <input
                  type="submit"
                  className="contact-btn tw-mb-4 tw-border-white  tw-text-white"
                  value="EKLE"
                />
              </div>
            </div>
          </form>
        </div>
        <table className="table pending_table">
          <thead className="thead-light">
            <tr>
              <th scope="col">No</th>
              <th scope="col">Resim</th>
              <th scope="col">Sil/Değiştir</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={item?._id} className="hover:tw-bg-slate-100">
                <td>
                  <span className="tw-text-xl">{item?.index}</span>
                </td>
                <td>
                  <div className="tw-flex tw-justify-center tw-items-center ">
                    <img
                      src={API_URL + item?.url}
                      alt="logo"
                      className="tw-w-20"
                    />
                  </div>
                </td>
                <td>
                  <div className="tw-flex tw-justify-center tw-items-center tw-text-4xl">
                    <FaRegTrashAlt
                      className="tw-cursor-pointer"
                      size={25}
                      color="red"
                      onClick={() =>
                        onDelete(item?._id, `referans${item?.index}`)
                      }
                    />{" "}
                    /{" "}
                    <FaEdit
                      className="tw-cursor-pointer"
                      size={25}
                      color="green"
                      onClick={() => navigate("/admin/ref/" + item?._id)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default AdminRef;
