import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AdminLogin from "./admin/AdminLogin";

import HizliYemek from "./pages/HizliYemek";
import AdminHome from "./admin/AdminHome";
import AdminResim from "./admin/AdminResim";
import AdminRef from "./admin/AdminRef";
import AdminSet from "./admin/AdminSet";
import ResimOne from "./admin/ResimOne";
import RefOne from "./admin/RefOne";
import Danisma from "./pages/Danisma";
import KuryeBasvuru from "./pages/KuryeBasvuru";
import AdminBasvuru from "./admin/AdminBasvuru";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/kuryebasvuru" element={<KuryeBasvuru />} />
          <Route path="/hizliyemek" element={<HizliYemek />} />
          <Route path="/danisma" element={<Danisma />} />
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/admin/anasayfa" element={<AdminHome />} />
          <Route path="/admin/resim" element={<AdminResim />} />
          <Route path="/admin/resim/:id" element={<ResimOne />} />
          <Route path="/admin/ref" element={<AdminRef />} />
          <Route path="/admin/ref/:id" element={<RefOne />} />
          <Route path="/admin/set" element={<AdminSet />} />
          <Route path="/admin/basvuru" element={<AdminBasvuru />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
