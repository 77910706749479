import React, { useEffect, useState } from "react";
import logo from "./../../images/delivery-logo.png";
import { API_URL } from "../../lib/config";
import axios from "axios";
import { Link } from "react-router-dom";
function Footer1() {
  const [data, setData] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(API_URL + "/set")
        .then((res) => {
          setData(res?.data);
        })
        .catch((error) => alert("Hata Oluştu"));
    };

    fetchData();
  }, []);

  return (
    <>
      <footer id="footer">
        <div className="pad-l-45 pad-r-45">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-md-6">
                <article className="footer-info-block">
                  <div className="section-sub-title">
                    <article className="section-title-body white">
                      <h4 className="head-title">Hakkımızda</h4>
                    </article>
                  </div>
                  <p>{data?.hakkimizda}</p>
                </article>
              </div>
              <div className="col-xl-3 col-md-6">
                <article className="footer-info-block">
                  <div className="section-sub-title">
                    <article className="section-title-body white">
                      <h4 className="head-title">Delivery Wi</h4>
                    </article>
                  </div>
                  <ul className="list-unstyled">
                    <li>
                      <Link to={"/hizliyemek"}>Hızlı Yemek Sipariş</Link>
                    </li>
                    <li>
                      <Link to={"/kuryebasvuru"}>Kurye Başvurusu</Link>
                    </li>
                    <li>
                      <Link to={"/"}>Restoran Başvurusu</Link>
                    </li>
                    <li>
                      <Link to={"/"}>Referanslar</Link>
                    </li>
                    <li>
                      <Link to={"/danisma"}>Danışma</Link>
                    </li>
                    <li>
                      <Link to={"/"}>İletişim</Link>
                    </li>
                  </ul>
                </article>
              </div>
              <div className="col-xl-3 col-md-6">
                <article className="footer-info-block">
                  <div className="section-sub-title">
                    <article className="section-title-body white">
                      <h4 className="head-title">Adress</h4>
                    </article>
                  </div>
                  <p>{data?.adress}</p>
                  <p>0850 807 03 32</p>
                  <p>{data?.tel}</p>
                </article>
              </div>
              <div className="col-xl-3 col-md-6">
                <article className="footer-info-block">
                  <div className="section-sub-title">
                    <article className="section-title-body white">
                      <h4 className="head-title">Media</h4>
                    </article>
                  </div>
                  <ul className="list-unstyled">
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=100089880949495&mibextid=ZbWKwL">
                        Facebook
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/delivery_wi?igsh=MXJ5ZnJ0cmRtNjBneg==">
                        İnstagram
                      </a>
                    </li>
                    <li>
                      <a href="https://api.whatsapp.com/send?phone=905311054208">
                        Whatsapp 
                      </a>
                    </li>
                    <li>
                      <a href="https://maps.app.goo.gl/MtSJVwKE9WX7pMbn9">
                        Konum
                      </a>
                    </li>
                  </ul>
                </article>
              </div>
            </div>
            <div className="h25"></div>
            <div className="footer-bottom">
              <div className="row">
                <div className="col-md-4 col-xs-12 col-sm-4">
                  <div className="footer-logo">
                    <img src={logo} alt="" className="tw-w-[250px]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer1;
