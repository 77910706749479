import Kuryemform from "../Forms/Kuryemform";

const KuryePage = () => {
  return (
    <>
      <Kuryemform />
    </>
  );
};

export default KuryePage;
