import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "./Layout";
import { useForm } from "react-hook-form";
import { API_URL } from "../lib/config";
import axios from "axios";

const RefOne = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [imageOne, setImageOne] = useState();
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user !== null) {
      const myuser = JSON.parse(user);
      if (myuser.name !== "admin") navigate("/");
    } else {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(API_URL + "/ref/" + id)
        .then((res) => {
          reset({
            index: res.data?.index,
          });
          setImageOne(API_URL + res.data.url);
        })
        .catch((error) => {
          alert("Hata Oluştu");
        });
    };

    fetchData();
  }, [id, reset]);

  const handleImageOne = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageOne(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("index", data.index);
    if (data.Image) formData.append("file", data.Image);

    try {
      const response = await axios.post(API_URL + "/upref/" + id, formData);
      if (response.data) {
        alert("Başarıyla Değiştirildi");
        window.location.reload();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) alert("Hata Oluştu");
    }
  };
  return (
    <Layout>
      <div className="row">
        <div className="col-lg-8">
          <form
            id="MyContactForm"
            name="KuryeForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <img
                  src={imageOne}
                  className="tw-w-[350px] tw-h-[350px] tw-object-contain"
                  alt="img1"
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2">
                  <p className="tw-text-white">..Referans Sırası..</p>
                  <input
                    type="number"
                    id="name"
                    min={0}
                    placeholder="No"
                    {...register(`index`)}
                  />
                  <p className="tw-text-white">..Resmi Değiştir..</p>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const maxSize = 2 * 1024 * 1024;

                      if (file.size > maxSize) {
                        alert("Dosya boyutu 2MB'dan fazla olamaz.");
                        e.target.value = "";
                      } else {
                        handleImageOne(e);
                        register(`Image`, {
                          shouldUnregister: true,
                          value: file,
                        });
                      }
                    }}
                  />
                  <div className="text-center">
                    <input
                      type="submit"
                      className="contact-btn tw-mb-4"
                      value="Kaydet"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default RefOne;
