import React from "react";
import Header1 from "../components/Header/Header1";
import SimpleSlider1 from "../components/Sliders/Slick-slider1";
import Footer1 from "../components/Footer/Footer1";
import Portfolio from "../components/Portfolio/Portfolio";

const HizliYemek = () => {
  return (
    <>
      <div id="home" className="full-screen-block">
        <Header1 />
        <SimpleSlider1 />
      </div>
      <Portfolio />
      <Footer1 />
    </>
  );
};

export default HizliYemek;
