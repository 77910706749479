import {
  Body,
  Container,
  Head,
  Heading,
  Html,
  Preview,
  Text,
} from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
import React from "react";

const Destek = ({ formData }) => {
  return (
    <Html>
      <Head />
      <Preview>Delivery Wi Form</Preview>
      <Tailwind
        config={{
          theme: {
            extend: {
              colors: {
                brand: "#2250f4",
                offwhite: "#fafbfb",
              },
              spacing: {
                0: "0px",
                20: "20px",
                45: "45px",
              },
            },
          },
        }}
      >
        <Body className="bg-offwhite text-base font-sans">
          <Container className="bg-white p-45">
            <Heading className="text-center my-0 leading-8">
              Delivery Wi Restoran Başvuru
            </Heading>

            <ul>
              <li className="mb-0">
                Ad Soyad : <strong>{formData?.name}</strong>
              </li>
              <li className="mb-0">
                Email : <strong> {formData?.email}</strong>
              </li>
              <li className="mb-0">
                Telefonu : <strong> {formData?.tel}</strong>
              </li>

              <li className="mb-0">
                Not : <strong> {formData?.message}</strong>
              </li>
            </ul>
          </Container>

          <Container className="mt-20">
            <Text className="text-center text-gray-400 mb-45">
              Duzyayla Sok., No:8, Musalla Bağları Mah., Selçuklu, Konya
            </Text>
          </Container>
        </Body>
      </Tailwind>
    </Html>
  );
};

export default Destek;
