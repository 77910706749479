import { render } from "@react-email/render";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { API_URL } from "../../lib/config";
import Restmail from "../Emails/Restmail";
Modal.setAppElement("#root");
function Restorantimform() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [ModalIsOpen, setModalIsOpen] = useState(false);
  const [tel, setTel] = useState("");
  const [telerror, setTelError] = useState("");
  const onSubmit = async (data) => {
    if (tel?.length < 7) {
      setTelError("Telefon Giriniz");
      return;
    }
    const formData = { ...data, tel: tel };

    const html = render(<Restmail formData={formData} />);
    const myformData = { html: html, role: "Delivery Wi Restorant Başvuru" };
    await axios
      .post(API_URL + "/sendmail", myformData)
      .then((res) => {
        setModalIsOpen(true);
      })
      .catch((error) => alert("Hata Oluştu"));
  };

  return (
    <>
      <Modal
        isOpen={ModalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="popup-send"
      >
        <div className="block-popup">
          <h4>Teşekkürler,</h4>
          <h4>En kısa sürede sizinle iletişime geçeceğiz.</h4>
        </div>
        <span
          onClick={() => setModalIsOpen(false)}
          className="close-btn"
        ></span>
      </Modal>
      <div id="form-wrapper">
        <div id="form-inner">
          <div id="MainResult"></div>
          <div id="MainContent">
            <form
              id="MyContactForm"
              name="KuryeForm"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <p>
                    <label htmlFor="name" id="nameLb">
                      <span className="!tw-capitalize tw-text-white tw-text-sm">
                        Restoran Adı :
                      </span>
                      <span className="error">{errors?.resad?.message}</span>
                    </label>

                    <input
                      type="text"
                      name="ad"
                      id="ad"
                      maxLength={70}
                      placeholder="Restoran Adı*"
                      {...register("resad", {
                        required: "Restoran Adı Giriniz.",
                      })}
                    />
                  </p>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <p>
                    <label htmlFor="tel" id="telLb">
                      <span className="!tw-capitalize tw-text-white tw-text-sm">
                        Restoran Telefonu :
                      </span>
                      <span className="error">{telerror}</span>
                    </label>

                    <input
                      type="text"
                      name="tel"
                      id="tel"
                      placeholder="Telefon*"
                      value={tel}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value) && value.length <= 15) {
                          setTel(value);
                        }
                      }}
                    />
                  </p>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <p>
                    <label htmlFor="email" id="emailLb">
                      <span className="!tw-capitalize tw-text-white tw-text-sm">
                        Email :
                      </span>
                      <span className="error">{errors?.resemail?.message}</span>
                    </label>

                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email*"
                      {...register("resemail", {
                        required: "Ulaşabileceğimiz Email giriniz.",
                      })}
                    />
                  </p>
                </div>

                <div className="col-md-12 col-sm-12 col-xs-12">
                  <p className="textarea">
                    <label htmlFor="adress" id="adressLb">
                      <span className="!tw-capitalize tw-text-white tw-text-sm">
                        Adress :
                      </span>
                      <span className="error">
                        {errors?.resadress?.message}
                      </span>
                    </label>

                    <textarea
                      id="adress"
                      placeholder="Restoran Adresi*"
                      rows="3"
                      maxLength={150}
                      {...register("resadress", {
                        required: "Ulaşabileceğimiz Adress giriniz.",
                      })}
                    ></textarea>
                  </p>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="text-center tw-mt-4">
                <input
                  type="submit"
                  className="contact-btn btn-move t2"
                  value="Görüşme Talep Et"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Restorantimform;
