import React, { useEffect, useState } from "react";
import ContactForm from "./../Contact/ContactForm";

import { API_URL } from "../../lib/config";
import axios from "axios";
function Contact1() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL + "/set");

        setData(response.data);
      } catch (error) {
        alert("Hata Oluştu");
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <section id="contacts" className="bg-non-fixed">
        <div className="bg-inner-dark2"></div>
        <div className="bg-50-l"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12  order-md-1 justify-content-center d-flex align-items-center">
              <div className="pad-l-45 pad-r-45">
                <div className="section-block-title">
                  <div className="section-title">
                    <article className="section-title-body mobile-mb-0">
                      <h1
                        className="head-title wow fadeInDown animated"
                        data-wow-duration="1.5s"
                      >
                        İLETİŞİM
                      </h1>
                    </article>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="pad-l-45 pad-r-45 block">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="widget-block mobile-center">
                      <h4 className="widget-title">
                        <i className="ion-android-pin"></i>Adress:
                      </h4>
                      <p>{data?.adress}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="widget-block mobile-center">
                      <h4 className="widget-title">
                        <i className="ion-android-call"></i>Phone:
                      </h4>
                      <p>
                        (0 850) 807 03 32
                        <br />
                        {data?.tel}
                      </p>
                    </div>
                  </div>
                </div>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact1;
