import React, { useState } from "react";
import Header1 from "../components/Header/Header1";
import SimpleSlider1 from "../components/Sliders/Slick-slider1";
import Footer1 from "../components/Footer/Footer1";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { render } from "@react-email/render";
import axios from "axios";
import { API_URL } from "../lib/config";
import Destek from "../components/Emails/Destek";
const Danisma = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [ModalIsOpen, setModalIsOpen] = useState(false);
  const [tel, setTel] = useState("");
  const [telerror, setTelError] = useState("");

  const onSubmit = async (data) => {
    if (tel?.length < 7) {
      setTelError("Telefon Giriniz");
      return;
    }
    const formData = { ...data, tel: tel };

    const html = render(<Destek formData={formData} />);
    const myformData = { html: html, role: "Delivery Wi İletişim" };
    await axios
      .post(API_URL + "/sendmail", myformData)
      .then((res) => {
        setModalIsOpen(true);
      })
      .catch((error) => alert("Hata Oluştu"));
  };
  return (
    <>
      <div id="home" className="full-screen-block">
        <Header1 />
        <SimpleSlider1 />
      </div>
      <Modal
        isOpen={ModalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="popup-send"
      >
        <div className="block-popup">
          <h4>Teşekkürler,</h4>
          <h4>Bu Verdiğiniz Önerileri Değerlendireceğiz.</h4>
        </div>
        <span
          onClick={() => setModalIsOpen(false)}
          className="close-btn"
        ></span>
      </Modal>
      <section id="danisma" className="bg-non-fixed">
        <div className="bg-inner-dark2"></div>
        <div className="bg-50-r"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-12 d-flex align-items-center">
              <div className="pad-l-45 pad-r-45">
                <div className="section-block-title">
                  <div className="section-title">
                    <article className="section-title-body mobile-mb-0">
                      <h1
                        className="head-title wow fadeInDown animated"
                        data-wow-duration="1.5s"
                      >
                        Danışma HİZMETİ
                      </h1>
                      <div
                        className="section-col wow fadeInUp animated"
                        data-wow-duration="1.5s"
                      ></div>
                      <p
                        className="head-text wow fadeInUp animated !tw-text-white"
                        data-wow-duration="1.5s"
                      >
                        KONU HAKKINDA BİLGİLERİNİZİ BİZE İLETİN...
                      </p>
                    </article>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="pad-l-45 pad-r-45 block">
                <div id="form-wrapper">
                  <div id="form-inner">
                    <div id="MainResult"></div>
                    <div id="MainContent">
                      <form
                        id="MyContactForm"
                        name="KuryeForm"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="row">
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <p>
                              <label htmlFor="name" id="nameLb">
                                <span className="!tw-capitalize tw-text-white tw-text-sm">
                                  Ad Soyad :
                                </span>
                                <span className="error">
                                  {errors?.ad?.message}
                                </span>
                              </label>

                              <input
                                type="text"
                                name="ad"
                                id="ad"
                                maxLength={70}
                                placeholder="Ad Soyadı*"
                                {...register("name", {
                                  required: "Ad Soyadı Giriniz.",
                                })}
                              />
                            </p>
                          </div>
                          <div className="col-md-6 col-sm-12 col-xs-12">
                            <p>
                              <label htmlFor="tel" id="telLb">
                                <span className="!tw-capitalize tw-text-white tw-text-sm">
                                  Telefon :
                                </span>
                                <span className="error">{telerror}</span>
                              </label>

                              <input
                                type="text"
                                name="tel"
                                id="tel"
                                placeholder="Telefon*"
                                value={tel}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (
                                    /^\d*$/.test(value) &&
                                    value.length <= 15
                                  ) {
                                    setTel(value);
                                  }
                                }}
                              />
                            </p>
                          </div>
                          <div className="col-md-6 col-sm-12 col-xs-12">
                            <p>
                              <label htmlFor="email" id="emailLb">
                                <span className="!tw-capitalize tw-text-white tw-text-sm">
                                  Email :
                                </span>
                                <span className="error">
                                  {errors?.email?.message}
                                </span>
                              </label>

                              <input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Email*"
                                {...register("email", {
                                  required: "Ulaşabileceğimiz Email giriniz.",
                                })}
                              />
                            </p>
                          </div>

                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <p className="textarea">
                              <label htmlFor="adress" id="adressLb">
                                <span className="!tw-capitalize tw-text-white tw-text-sm">
                                  Not :
                                </span>
                                <span className="error">
                                  {errors?.not?.message}
                                </span>
                              </label>

                              <textarea
                                id="adress"
                                placeholder="Not*"
                                rows="3"
                                maxLength={150}
                                {...register("message", {
                                  required: "Bir Konu giriniz.",
                                })}
                              ></textarea>
                            </p>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="text-center tw-mt-4">
                          <input
                            type="submit"
                            className="contact-btn btn-move t2"
                            value="Gönder"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer1 />
    </>
  );
};

export default Danisma;
