import React from "react";
import { useForm } from "react-hook-form";
import logo from "../images/delivery-logo.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../lib/config";

const AdminLogin = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      const response = await axios.post(API_URL + "/auth/login", data);
      if (response.data) {
        await localStorage.setItem("user", JSON.stringify(response.data.data));
        navigate("/admin/anasayfa");
      }
    } catch (error) {
      if (error.response && error.response.status === 500) alert("Hata Oluştu");
    }
  };
  return (
    <div className="container ">
      <div className="tw-flex tw-items-center tw-justify-center tw-h-screen">
        <div className="tw-flex tw-flex-col tw-gap-5 tw-items-center tw-justify-center">
          <img src={logo} alt="logo" className="tw-w-60" />

          <form
            id="MyContactForm"
            name="KuryeForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="row">
              <div className="col-12 ">
                <p>
                  <label htmlFor="name" id="nameLb">
                    <span className="tw-text-white tw-flex tw-justify-center tw-mb-2">
                      Kullanıcı Adı
                    </span>
                  </label>
                  <input
                    type="text"
                    id="user"
                    placeholder="Kullanıcı Adı*"
                    {...register("name")}
                  />
                </p>
              </div>
              <div className="col-12 ">
                <p>
                  <label htmlFor="name" id="nameLb">
                    <span className="tw-text-white tw-flex tw-justify-center tw-mb-2">
                      Şifre
                    </span>
                  </label>
                  <input
                    type="password"
                    id="pass"
                    placeholder="Password*"
                    {...register("pass")}
                  />
                </p>
              </div>
              <div className="col-12 ">
                <div className="text-center tw-mt-4">
                  <input type="submit" className="contact-btn " value="Giriş" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
