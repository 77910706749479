import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import axios from "axios"; // axios kütüphanesini ekledim
import { API_URL } from "../../lib/config";
import "./slider.css";
const SimpleSlider1 = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL + "/set");
        setData(response?.data?.banners);
      } catch (error) {
        alert("Hata Oluştu");
      }
    };

    fetchData();
  }, []);

  const [isWide, setIsWide] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsWide(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const checkValues = [
    data?.bannerOne?.check,
    data?.bannerTwo?.check,
    data?.bannerThree?.check,
  ];
  const check = checkValues.filter(Boolean).length > 1;
  const settings = {
    dots: false,
    arrows: false,
    infinite: check,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
  };

  return (
    <>
      <Slider {...settings}>
        {data && data?.bannerOne?.check === true && (
          <div className="full-screen-block ">
            {isWide === true ? (
              <img
                src={API_URL + data?.bannerOne?.url}
                alt="bannerone"
                className="tw-h-full tw-w-full "
              />
            ) : (
              <img
                src={API_URL + data?.bannerOne?.mobilurl}
                alt="bannerone"
                className="tw-h-full tw-w-full "
              />
            )}
          </div>
        )}
        {data && data?.bannerTwo?.check === true && (
          <div className="full-screen-block ">
            {isWide === true ? (
              <img
                src={API_URL + data?.bannerTwo?.url}
                alt="bannerone"
                className="tw-h-full tw-w-full "
              />
            ) : (
              <img
                src={API_URL + data?.bannerTwo?.mobilurl}
                alt="bannerone"
                className="tw-h-full tw-w-full "
              />
            )}
          </div>
        )}

        {data && data?.bannerThree?.check === true && (
          <div className="full-screen-block ">
            {isWide === true ? (
              <img
                src={API_URL + data?.bannerThree?.url}
                alt="bannerone"
                className="tw-h-full tw-w-full "
              />
            ) : (
              <img
                src={API_URL + data?.bannerThree?.mobilurl}
                alt="bannerone"
                className="tw-h-full tw-w-full "
              />
            )}
          </div>
        )}
      </Slider>
    </>
  );
};

export default SimpleSlider1;
