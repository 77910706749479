import React from "react";
import Lightbox from "./Lightbox";

function Portfolio() {
  return (
    <>
      <section id="portfolios" className="bg-non-fixed">
        <div className="bg-inner-dark2"></div>
        <div className="bg-100"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="pad-l-45 pad-r-45 block tw-pt-10 md:tw-pt-20">
                <Lightbox />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Portfolio;
