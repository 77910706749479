import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";
import { useForm } from "react-hook-form";
import axios from "axios";
import { API_URL } from "../lib/config";

const AdminBasvuru = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm();
  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user !== null) {
      const myuser = JSON.parse(user);
      if (myuser.name !== "admin") navigate("/");
    } else {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(API_URL + "/set")
        .then((res) => {
          reset({
            basvuruOneName: res.data?.kuryebasvuru?.basvuruOne?.name,
            basvuruOneText: res.data?.kuryebasvuru?.basvuruOne?.text,
            basvuruOneCheck: res.data?.kuryebasvuru?.basvuruOne?.check,
            basvuruTwoName: res.data?.kuryebasvuru?.basvuruTwo?.name,
            basvuruTwoText: res.data?.kuryebasvuru?.basvuruTwo?.text,
            basvuruTwoCheck: res.data?.kuryebasvuru?.basvuruTwo?.check,
            basvuruThreeName: res.data?.kuryebasvuru?.basvuruThree?.name,
            basvuruThreeText: res.data?.kuryebasvuru?.basvuruThree?.text,
            basvuruThreeCheck: res.data?.kuryebasvuru?.basvuruThree?.check,
            basvuruFourName: res.data?.kuryebasvuru?.basvuruFour?.name,
            basvuruFourText: res.data?.kuryebasvuru?.basvuruFour?.text,
            basvuruFourCheck: res.data?.kuryebasvuru?.basvuruFour?.check,
            basvuruFiveName: res.data?.kuryebasvuru?.basvuruFive?.name,
            basvuruFiveText: res.data?.kuryebasvuru?.basvuruFive?.text,
            basvuruFiveCheck: res.data?.kuryebasvuru?.basvuruFive?.check,
            basvuruSixName: res.data?.kuryebasvuru?.basvuruSix?.name,
            basvuruSixText: res.data?.kuryebasvuru?.basvuruSix?.text,
            basvuruSixCheck: res.data?.kuryebasvuru?.basvuruSix?.check,
          });
        })
        .catch((error) => {
          console.log(error);
          alert("Hata Oluştu");
        });
    };

    fetchData();
  }, [reset]);

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(API_URL + "/upbasvuru", data);
      if (response.data) {
        alert("Başarıyla Değiştirildi");
        window.location.reload();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) alert("Hata Oluştu");
    }
  };
  return (
    <Layout>
      <form
        id="MyContactForm"
        name="KuryeForm"
        className="tw-border tw-border-white tw-p-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row">
          <div className="col-12 tw-mt-6">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <input
                  type="text"
                  id="name"
                  placeholder="Başvuru 1 Başlık"
                  {...register(`basvuruOneName`)}
                />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <textarea
                  id="text"
                  placeholder="Başvuru 1 Açıklama*"
                  rows="3"
                  {...register("basvuruOneText")}
                ></textarea>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <div className="tw-flex tw-items-center tw-justify-start tw-space-x-4">
                  <input
                    type="checkbox"
                    className="tw-mb-0 tw-w-12 tw-min-h-4 !tw-px-2"
                    {...register("basvuruOneCheck")}
                  />
                  <div className="tw-text-white">GÖSTER</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 tw-mt-6">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <input
                  type="text"
                  id="name"
                  placeholder="Başvuru 2 Başlık"
                  {...register(`basvuruTwoName`)}
                />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <textarea
                  id="text"
                  placeholder="Başvuru 2 Açıklama*"
                  rows="3"
                  {...register("basvuruTwoText")}
                ></textarea>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <div className="tw-flex tw-items-center tw-justify-start tw-space-x-4">
                  <input
                    type="checkbox"
                    className="tw-mb-0 tw-w-12 tw-min-h-4 !tw-px-2"
                    {...register("basvuruTwoCheck")}
                  />
                  <div className="tw-text-white">GÖSTER</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 tw-mt-6">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <input
                  type="text"
                  id="name"
                  placeholder="Başvuru 3 Başlık"
                  {...register(`basvuruThreeName`)}
                />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <textarea
                  id="text"
                  placeholder="Başvuru 3 Açıklama*"
                  rows="3"
                  {...register("basvuruThreeText")}
                ></textarea>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <div className="tw-flex tw-items-center tw-justify-start tw-space-x-4">
                  <input
                    type="checkbox"
                    className="tw-mb-0 tw-w-12 tw-min-h-4 !tw-px-2"
                    {...register("basvuruThreeCheck")}
                  />
                  <div className="tw-text-white">GÖSTER</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 tw-mt-6">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <input
                  type="text"
                  id="name"
                  placeholder="Başvuru 4 Başlık"
                  {...register(`basvuruFourName`)}
                />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <textarea
                  id="text"
                  placeholder="Başvuru 4 Açıklama*"
                  rows="3"
                  {...register("basvuruFourText")}
                ></textarea>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <div className="tw-flex tw-items-center tw-justify-start tw-space-x-4">
                  <input
                    type="checkbox"
                    className="tw-mb-0 tw-w-12 tw-min-h-4 !tw-px-2"
                    {...register("basvuruFourCheck")}
                  />
                  <div className="tw-text-white">GÖSTER</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 tw-mt-6">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <input
                  type="text"
                  id="name"
                  placeholder="Başvuru 5 Başlık"
                  {...register(`basvuruFiveName`)}
                />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <textarea
                  id="text"
                  placeholder="Başvuru 5 Açıklama*"
                  rows="3"
                  {...register("basvuruFiveText")}
                ></textarea>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <div className="tw-flex tw-items-center tw-justify-start tw-space-x-4">
                  <input
                    type="checkbox"
                    className="tw-mb-0 tw-w-12 tw-min-h-4 !tw-px-2"
                    {...register("basvuruFiveCheck")}
                  />
                  <div className="tw-text-white">GÖSTER</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 tw-mt-6">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <input
                  type="text"
                  id="name"
                  placeholder="Başvuru 6 Başlık"
                  {...register(`basvuruSixName`)}
                />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <textarea
                  id="text"
                  placeholder="Başvuru 6 Açıklama*"
                  rows="3"
                  {...register("basvuruSixText")}
                ></textarea>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <div className="tw-flex tw-items-center tw-justify-start tw-space-x-4">
                  <input
                    type="checkbox"
                    className="tw-mb-0 tw-w-12 tw-min-h-4 !tw-px-2"
                    {...register("basvuruSixCheck")}
                  />
                  <div className="tw-text-white">GÖSTER</div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center ">
            <input
              type="submit"
              className="contact-btn tw-my-4 tw-border-white  tw-text-white"
              value="DEĞİŞTİR"
            />
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default AdminBasvuru;
