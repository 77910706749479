import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import { API_URL } from "../../lib/config";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDropleftCircle } from "react-icons/io";

import "./refcss.css";
const SliderClients = () => {
  const [data, setData] = useState([]);

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow right-arrow " onClick={onClick}>
        <IoIosArrowDroprightCircle color="white" />
      </div>
    );
  };
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow left-arrow " onClick={onClick}>
        <IoIosArrowDropleftCircle color="white" />
      </div>
    );
  };
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 8,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL + "/ref");
        setData(response.data);
      } catch (error) {
        alert("Hata Oluştu");
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Slider {...settings}>
        {data?.map((item) => {
          return (
            <div className="text-center" key={item?.index}>
              <img
                src={API_URL + item?.url}
                alt={`ref${item?.index}`}
                className="img-fluid"
              />
            </div>
          );
        })}
      </Slider>
    </>
  );
};

export default SliderClients;
