import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "./../../images/delivery-logo.png";
import "./style.css";
const SideBar = () => {
  const location = useLocation();
  return (
    <>
      <div className="col-sm-12 col-md-12 col-lg-2 col_xl_2">
        <div className="dashboard_tab_button ">
          <ul role="tablist" className="nav flex-column">
            <li className="tw-my-4">
              <img src={logo} alt="logo" className="tw-w-60" />
            </li>
            <li>
              <Link to="/">
                <i className="fa fa-tachometer"></i>Website
              </Link>
            </li>
            <li>
              <Link
                to="/admin/anasayfa"
                className={
                  location.pathname === "/admin/anasayfa" ? "active" : null
                }
              >
                Anasayfa
              </Link>
            </li>
            <li>
              <Link
                to="/admin/resim"
                className={
                  location.pathname === "/admin/resim" ? "active" : null
                }
              >
                Hızlı-Yemek Resimler
              </Link>
            </li>
            <li>
              <Link
                to="/admin/ref"
                className={location.pathname === "/admin/ref" ? "active" : null}
              >
                Referanslar
              </Link>
            </li>
            <li>
              <Link
                to="/admin/basvuru"
                className={
                  location.pathname === "/admin/basvuru" ? "active" : null
                }
              >
                Başvuru Ayarları
              </Link>
            </li>
            <li>
              <Link
                to="/admin/set"
                className={location.pathname === "/admin/set" ? "active" : null}
              >
                Ayarlar
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideBar;
