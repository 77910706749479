import React, { useState } from "react";
import Modal from "react-modal";
import { API_URL } from "../../lib/config";
import axios from "axios";
import { useForm } from "react-hook-form";
import { render } from "@testing-library/react";
import Destek from "../Emails/Destek";
Modal.setAppElement("#root");
function ContactForm() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [ModalIsOpen, setModalIsOpen] = useState(false);

  const [tel, setTel] = useState("");
  const [telerror, setTelError] = useState("");
  const onSubmit = async (data) => {
    if (tel?.length < 7) {
      setTelError("Telefon Giriniz");
      return;
    }
    const formData = { ...data, tel: tel };

    const html = render(<Destek formData={formData} />);
    const myformData = { html: html, role: "Delivery Wi İletişim" };
    await axios
      .post(API_URL + "/sendmail", myformData)
      .then((res) => {
        setModalIsOpen(true);
      })
      .catch((error) => alert("Hata Oluştu"));
  };
  return (
    <>
      <Modal
        isOpen={ModalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="popup-send"
      >
        <div className="block-popup">
          <h4>Teşekkürler.</h4>
          <h4>Bildirim için teşekkür ederiz. </h4>
        </div>
        <span
          onClick={() => setModalIsOpen(false)}
          className="close-btn"
        ></span>
      </Modal>
      <div id="form-wrapper">
        <div id="form-inner">
          <div id="MainResult"></div>
          <div id="MainContent">
            <form
              id="MyContactForm"
              name="MyContactForm"
              method="post"
              onSubmit={handleSubmit(onSubmit)}
            >
              <p className="name">
                <label htmlFor="name" id="nameLb">
                  <span className="error">{errors?.name?.message}</span>
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  maxLength={70}
                  placeholder="Ad Soyad*"
                  {...register("name", {
                    required: "Ad Soyad Giriniz.",
                  })}
                />
              </p>
              <p>
                <label htmlFor="email" id="emailLb">
                  <span className="error">{errors?.email?.message}</span>
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email*"
                  {...register("email", {
                    required: "Email giriniz.",
                  })}
                />
              </p>
              <p>
                <label htmlFor="phone" id="phoneLb">
                  <span className="error">{telerror}</span>
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Telefon"
                  value={tel}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value) && value.length <= 15) {
                      setTel(value);
                    }
                  }}
                />
              </p>
              <p className="textarea">
                <label htmlFor="message" id="messageLb">
                  <span className="error">{errors?.message?.message}</span>
                </label>
                <textarea
                  name="message"
                  id="message"
                  placeholder="Your Message*"
                  rows="4"
                  {...register("message", {
                    required: "Mesajınızı giriniz",
                  })}
                ></textarea>
              </p>
              <div className="clearfix"></div>
              <div className="text-center">
                <input
                  type="submit"
                  className="contact-btn btn-move t2"
                  value="Gönder"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
